import { useAgency } from 'context/agency'
import { useLocationInfo } from 'context/location'

function AppLogo() {
  const { location } = useLocationInfo()
  const { agency } = useAgency()
  
  const alphabets = location?.name || location?.basic_info?.name || 'Location'
  const slicedAlphabets = alphabets.slice(0, 12)
  const dot = slicedAlphabets + '...'
  return (
    <div className="px-3 flex items-center justify-start rtl:justify-end">
      <a href="/" className="flex ms-2 justify-start">
        <div className=''>
          <img
          src={location?.basic_info?.logo || 'https://placehold.co/600x600'}
          className="w-8 h-8 rounded-full object-cover me-3"
          alt="FlowBite Logo"
        />
        </div>
        <div
          className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap"
          style={{
            color: agency.location_config?.sidenav_text_color,
          }}
        >
          {dot}
        </div>
      </a>
    </div>
  )
}

export default AppLogo
