import React from 'react'

function WalletIcon({ className, color }) {
  return (
    <svg
      class={`h-6 w-6 text-inherit ${className}`}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      stroke-width='2'
      stroke='currentColor'
      fill={color || 'none'}
      stroke-linecap='round'
      stroke-linejoin='round'
    >
      {' '}
      <path stroke='none' d='M0 0h24v24H0z' />{' '}
      <path d='M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12' />{' '}
      <path d='M20 12v4h-4a2 2 0 0 1 0 -4h4' />
    </svg>
  )
}

export default WalletIcon
