import PublicRoutes from './public-routes'

const AppRoutes = [
  {
    path: '',
    children: [],
  },
  ...PublicRoutes,
]

export default AppRoutes
