import React, { lazy } from 'react'
import ComponentLoader from './component-loader'
import { useAgency } from 'context/agency'
import { PREMIUM_APPS } from 'enums'

const Chat = ComponentLoader(lazy(() => import('screens/admin/Chat')))

// TICKET
const TicketModule = ComponentLoader(lazy(() => import('screens/admin/Ticket')))
const Ticket = ComponentLoader(
  lazy(() => import('screens/admin/Ticket/ticket-list'))
)
const CreateTicket = ComponentLoader(
  lazy(() => import('screens/admin/Ticket/ticket-create'))
)
const ViewTicket = ComponentLoader(
  lazy(() => import('screens/admin/Ticket/ticket-view'))
)

// CITY PASS
const Citypass = ComponentLoader(lazy(() => import('screens/admin/CityPass')))

const PremiumAppsRoutes = () => {
  const { agency } = useAgency()

  const routes = []

  const isAppExist = (app) =>
    agency?.premium_apps?.find((item) =>
      item.upsale_id?.products?.includes(app)
    )

  if (isAppExist(PREMIUM_APPS.chat))
    routes.push({
      path: 'chat',
      element: <Chat />,
    })

  if (isAppExist(PREMIUM_APPS.ticket))
    routes.push({
      path: 'ticket',
      element: <TicketModule />,
      children: [
        {
          path: '',
          index: true,
          element: <Ticket />,
        },
        {
          path: 'create',
          element: <CreateTicket />,
        },
        {
          path: 'view',
          element: <ViewTicket />,
        },
      ],
    })

  if (isAppExist(PREMIUM_APPS.citypass))
    routes.push({
      path: 'citypass',
      element: <Citypass />,
    })

  return routes
}

export default PremiumAppsRoutes
