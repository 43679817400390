import React from 'react'
import { Navigate } from 'react-router-dom'
import PublicRoutes from './public-routes'

const GHLRoutes = [
  {
    path: '/crm/:location_id/:location_name/:location_address',
    children: [],
  },
  ...PublicRoutes,
  {
    path: '*',
    element: (
      <Navigate to={'/crm/:location_id/:location_name/:location_address'} />
    ),
  },
  {
    path: '',
    element: (
      <Navigate to={'/crm/:location_id/:location_name/:location_address'} />
    ),
  },
]

export default GHLRoutes
