import * as USER from './user'
import * as CAMPAIGN from './campaigns'
import * as PASS from './pass'
import * as GROWTHTOOL from './growth-tool'
import * as EVENTS from './events'

const Environment = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',
}

const ReqMethods = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
}
const NotificationTypes = {
  ANNOUNCEMENT: 'announcement',
  EVENT: 'event',
}
const PREMIUM_APPS = {
  chat: 'chat',
  ticket: 'ticket',
  docs: 'docs',
  citypass: 'citypass',
}

export {
  USER,
  CAMPAIGN,
  PASS,
  GROWTHTOOL,
  EVENTS,
  NotificationTypes,
  Environment,
  ReqMethods,
  PREMIUM_APPS,
}
