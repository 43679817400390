import React, { useState, useEffect, createContext, useContext } from 'react'
import Api from 'utils/api'
import Loader from 'component/loader'

const initDocsContextPropsState = {
  docs: [],
  setDocs: () => {},
  openModal: false,
  setOpenModal: () => {},
  selectedDoc: undefined,
  setSelectedDoc: () => {},
}

const DocContext = createContext(initDocsContextPropsState)

const useDocs = () => {
  return useContext(DocContext)
}

const DocProvider = ({ children }) => {
  const [loader, setLoader] = useState(true)
  const [docs, setDocs] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [selectedDoc, setSelectedDoc] = useState()

  const GetDocs = async () => {
    const { response } = await Api('/location/available_docs', 'get')
    setLoader(false)
    if (response) setDocs(response.data)
  }

  const onLoad = () => {
    GetDocs()
  }

  useEffect(onLoad, [])

  return (
    <DocContext.Provider
      value={{
        docs,
        setDocs,
        openModal,
        setOpenModal,
        selectedDoc,
        setSelectedDoc,
      }}
    >
      {loader ? <Loader /> : children}
    </DocContext.Provider>
  )
}

export { DocProvider, useDocs }
