import AppLogo from 'component/Sidebar/app-logo'
import ProfileButton from 'component/Sidebar/profile-button'
import TopHeader from 'component/Sidebar/top-header'
import React from 'react'
import { Outlet } from 'react-router-dom'

function SubscriptionExpiredLayout() {
  return (
    <div>
      <div className="p-4 flex justify-between fixed top-0 z-50 w-full bg-white border-b border-gray-200">
        <AppLogo />

        <TopHeader />
      </div>
      <main className="w-full p-8 mt-16">
        <Outlet />
      </main>
    </div>
  )
}

export default SubscriptionExpiredLayout
