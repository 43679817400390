import React from 'react'
import { useAuth } from 'context/auth'
import AuthRoutes from './auth-routes'
import AdminRoutes from './admin-routes'
import NoLocationRoutes from './nolocation-routes'
import InvalidAccessRoutes from './unauthorize-routes'
import SubscriptionExpiredRoutes from './subscription-expired-routes'
import ChangePassRequiredRoutes from './change-pass-req-routes'
import { TYPES } from 'enums/user'
import { useLocationInfo } from 'context/location'
import GHLRoutes from './ghl-routes'
import AppRoutes from './app-routes'
import { isGHLPlatform } from 'utils/auth-helper'

const Routes = () => {
  const { currentUser } = useAuth()
  const { location } = useLocationInfo()
  const currentRoutes = isGHLPlatform() ? GHLRoutes : AppRoutes
  const routes = []

  // PUBLIC
  if (!currentUser) {
    currentRoutes[0].children = routes.concat(AuthRoutes)
    return currentRoutes
  }

  const validUserTypes = [TYPES.AGENCY, TYPES.LOCATION]

  // PREMISSION DENIED
  if (currentUser && !validUserTypes.includes(currentUser.user_type)) {
    currentRoutes[0].children = routes.concat(InvalidAccessRoutes)
    return currentRoutes
  }

  // NO AGENCY FOUND
  if (currentUser && !location) {
    currentRoutes[0].children = routes.concat(NoLocationRoutes)
    return currentRoutes
  }

  // CHANGE PASSWORD REQUIRED
  if (currentUser && currentUser.change_pass_required) {
    currentRoutes[0].children = routes.concat(ChangePassRequiredRoutes)
    return currentRoutes
  }

  // // Subscription Expired Layout
  // if (location?.stripe && location?.stripe?.status !== 'active') {
  //   currentRoutes[0].children = routes.concat(SubscriptionExpiredRoutes)
  //   return currentRoutes
  // }
  if (currentUser) {
    // ADMIN
    currentRoutes[0].children = routes.concat(AdminRoutes())
    return currentRoutes
  }

  return currentRoutes
}

export default Routes
