import { Environment } from 'enums'
import LocalEnv from './env.local'
import DevEnv from './env.dev'
import ProdEnv from './env.prod'

let env = LocalEnv

// const env = {
//   SERVER: 'http://localhost:8082',
//   API_URL: 'http://localhost:8082/api',
//   // API_URL: 'http://192.168.18.158:8082/api',
//   GHL: {
//     V2_BASE_URL: 'https://api.msgsndr.com',
//     // Wallet Connector
//     CLIENT_ID: '64f200689bace7ace6a41004-lm7qnqtw',
//     CLIENT_SECRET: '2c08791b-15d0-453c-875d-95a7051167d7',
//     SCOPE:
//       'businesses.readonly businesses.write calendars.readonly calendars.write calendars/events.readonly calendars/events.write campaigns.readonly conversations.readonly conversations.write conversations/message.readonly conversations/message.write contacts.readonly contacts.write forms.readonly forms.write links.readonly links.write locations.readonly locations/customValues.readonly locations/customValues.write locations/customFields.readonly locations/customFields.write locations/tasks.readonly locations/tasks.write locations/tags.readonly locations/tags.write medias.readonly locations/templates.readonly medias.write opportunities.readonly opportunities.write surveys.readonly users.readonly users.write workflows.readonly',
//     REDIRECT: {
//       ASSOCIATE: 'http://localhost:3030/integrations/ghl_location',
//     },
//   },
//   STRIPE: {
//     PUBLIC_KEY:
//       'pk_test_51M7zTzGZOzsN0UuRLyM3qnOwVAOLRNjLaURRpYfwFSyUsi6fKB7VNQTwCzsNBwwzorRBXRPXA5xRqRgg9Txt2NAf000HSNLkUC', // walletcampaigns
//     CLIENT_ID: 'ca_Mrj5a8X6y0mveoxrFTkewDhfo7PYkhbJ', // walletcampaigns
//   },
//   LOCATION_LEVEL: 'http://localhost:3030',
//   SCAN_APP: 'http://localhost:3050',
//   POSITION_STACK_KEY: '394d32c306957260723b4f4268497d24', // not working
//   G_MAP_API_KEY: 'AIzaSyC7Bj8PVfTnNIm8j2x7zEXT0-4bckwiM00',
// }

if (process.env.REACT_APP_ENV === Environment.STAGING) {
  env = Object.assign(env, DevEnv)

  // env.API_URL = 'https://dev.api.walletcampaigns.com/api'
  // env.SERVER = 'https://dev.api.walletcampaigns.com'
  // env.GHL.REDIRECT.ASSOCIATE =
  //   'https://dev.app.walletcampaigns.com/integrations/ghl_location'
  // env.SCAN_APP = 'https://dev.scan.walletcampaigns.com/'
}

if (process.env.REACT_APP_ENV === Environment.PRODUCTION) {
  env = Object.assign(env, ProdEnv)

  // env.API_URL = 'https://api.walletcampaigns.com/api'
  // env.SERVER = 'https://api.walletcampaigns.com'
  // env.GHL.REDIRECT.ASSOCIATE =
  //   'https://app.walletcampaigns.com/integrations/ghl_location'
  // env.SCAN_APP = 'https://scan.walletcampaigns.com/'
}

export default env
