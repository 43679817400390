import Select from 'component/Select/select'
import ProfileButton from './profile-button'
import { useLocationInfo } from 'context/location'
import Api from 'utils/api'
import { useAuth } from 'context/auth'
import Side from 'screens/admin/Docs/side'
import { useNavigate } from 'react-router-dom'

function TopHeader() {
  const { setCurrentUser } = useAuth()
  const { locations, locationKey, saveLocationKey } = useLocationInfo()

  const handleSelectLocation = async (e) => {
    const { value } = e.target
    const location = await locations.find((loc) => loc.api_key === value)
    const { response } = await Api('/user', 'put', {
      payload: { selected_location: location.api_key },
      notify: true,
    })
    if (response) {
      saveLocationKey(location.api_key)
      setCurrentUser(response.data)
    }
  }

  return (
    <div className='flex items-center'>
      <div className='flex items-center '>
        <div class='mr-4'>
          <Select
            onSelect={handleSelectLocation}
            placeholder={'Select Location'}
            value={locationKey}
            options={locations.map((location) => ({
              label: location.name || location.basic_info?.name,
              value: location.api_key,
            }))}
          />
        </div>
        <div class='mr-2'>
          <Side />
        </div>
        <div>
          <ProfileButton />
        </div>
      </div>
    </div>
  )
}

export default TopHeader
