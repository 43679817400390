import { Environment } from 'enums'

const env = {
  SERVER: 'https://api.walletcampaigns.com',
  API_URL: 'https://api.walletcampaigns.com/api',
  GHL: {
    V2_BASE_URL: 'https://api.msgsndr.com',
    // Wallet Connector
    CLIENT_ID: '64f200689bace7ace6a41004-lm7qnqtw',
    CLIENT_SECRET: '2c08791b-15d0-453c-875d-95a7051167d7',
    SCOPE:
      'businesses.readonly businesses.write calendars.readonly calendars.write calendars/events.readonly calendars/events.write campaigns.readonly conversations.readonly conversations.write conversations/message.readonly conversations/message.write contacts.readonly contacts.write forms.readonly forms.write links.readonly links.write locations.readonly locations/customValues.readonly locations/customValues.write locations/customFields.readonly locations/customFields.write locations/tasks.readonly locations/tasks.write locations/tags.readonly locations/tags.write medias.readonly locations/templates.readonly medias.write opportunities.readonly opportunities.write surveys.readonly users.readonly users.write workflows.readonly',
    REDIRECT: 'https://app.walletcampaigns.com/integrations/ghl_location',
  },
  STRIPE: {
    PUBLIC_KEY:
      'pk_live_51M7zTzGZOzsN0UuR5NFf5og4q7ZhiahifU305gDqnqIXLISh6Oek62dYbUnXRPzQkt9BpM3QavyrsjM2KXLAt2x400xcEDSPRS', // walletcampaigns
    CLIENT_ID: 'ca_Mrj5xmu7CRAeF3mF55TV6vKvgSCJCUQJ', // walletcampaigns
  },
  LOCATION_LEVEL: 'https://app.walletcampaigns.com',
  SCAN_APP: 'https://scan.walletcampaigns.com/',
  POSITION_STACK_KEY: '394d32c306957260723b4f4268497d24', // not working
  G_MAP_API_KEY: 'AIzaSyC7Bj8PVfTnNIm8j2x7zEXT0-4bckwiM00',
}

export default env
