import React, { lazy } from 'react'
import ComponentLoader from './component-loader'
import { Navigate } from 'react-router-dom'
import SubscriptionExpiredLayout from 'screens/subscription-expired/subscription-expired-layout'

const SubscriptionExpired = ComponentLoader(
  lazy(() => import('screens/subscription-expired/subscription-expired'))
)

const Profile = ComponentLoader(
  lazy(() => import('screens/admin/Profile/profile'))
)

const InvalidAccessRoutes = [
  {
    path: '',
    element: <SubscriptionExpiredLayout />,
    children: [
      {
        path: '/subscription-expired',
        index: true,
        element: <SubscriptionExpired />,
      },
      {
        path: '/profile',
        index: true,
        element: (
          <div className="max-w-3xl mx-auto">
            <Profile />
          </div>
        ),
      },
      {
        path: '',
        index: true,
        element: <Navigate replace to={'/subscription-expired'} />,
      },
      {
        path: '*',
        index: true,
        element: <Navigate replace to={'/subscription-expired'} />,
      },
    ],
  },
]

export default InvalidAccessRoutes
