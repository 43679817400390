import React from 'react'

function Modal({ open, onClose, children }) {
  return (
    <div>
      {open && (
        <div
          onClick={onClose}
          className="fixed inset-0 overflow-y-auto overflow-x-hidden z-50 flex items-center justify-center bg-black bg-opacity-50"
        >
          <div onClick={(e) => e.stopPropagation()} className="relative p-4">
            <div className="relative bg-white rounded-lg shadow">
              {children}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Modal
