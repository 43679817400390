import { Environment } from 'enums'

const env = {
  SERVER: 'http://localhost:8082',
  API_URL: 'http://localhost:8082/api',
  // API_URL: 'http://192.168.18.158:8082/api',
  GHL: {
    V2_BASE_URL: 'https://api.msgsndr.com',
    //dev-L-Location
    CLIENT_ID: '6657377ac7237b41dff768a2-lwrx1jl8',
    CLIENT_SECRET: 'f56c407e-aba2-461f-9e3a-3f290d16bf6c',
    SCOPE:
      'businesses.readonly businesses.write calendars.readonly calendars.write calendars/events.readonly calendars/events.write campaigns.readonly conversations.readonly conversations.write conversations/message.readonly conversations/message.write contacts.readonly contacts.write forms.readonly forms.write links.readonly links.write locations.readonly locations/customValues.readonly locations/customValues.write locations/customFields.readonly locations/customFields.write locations/tasks.readonly locations/tasks.write locations/tags.readonly locations/tags.write medias.readonly locations/templates.readonly medias.write opportunities.readonly opportunities.write surveys.readonly users.readonly users.write workflows.readonly',
    REDIRECT: 'http://localhost:3000/integrations/crm/associate',
  },
  STRIPE: {
    PUBLIC_KEY:
      'pk_test_51M7zTzGZOzsN0UuRLyM3qnOwVAOLRNjLaURRpYfwFSyUsi6fKB7VNQTwCzsNBwwzorRBXRPXA5xRqRgg9Txt2NAf000HSNLkUC', // walletcampaigns
    CLIENT_ID: 'ca_Mrj5a8X6y0mveoxrFTkewDhfo7PYkhbJ', // walletcampaigns
  },
  LOCATION_LEVEL: 'http://localhost:3030',
  SCAN_APP: 'http://localhost:3050',
  POSITION_STACK_KEY: '394d32c306957260723b4f4268497d24', // not working
  G_MAP_API_KEY: 'AIzaSyC7Bj8PVfTnNIm8j2x7zEXT0-4bckwiM00',
}

export default env
