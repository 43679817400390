const TYPES = {
  AGENCY: 'agency_user',
  LOCATION: 'location_user',
  SUBSCRIBER: 'subscriber',
  SUPER_ADMIN: 'super_admin',
}

const ROLES = {
  AGENCY: 'agency',
  ADMIN: 'admin',
  POS: 'pos',
  CONSUMER: 'consumer',
}

export { TYPES, ROLES }
