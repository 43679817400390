const FieldReferencePlatform = {
  WALLETCAMPAIGNS: 'walletcampaigns',
  GHL: 'ghl',
}

const FieldReferenceTable = {
  USER: 'user',
  LOCATION: 'location',
  BUSINESS: 'business',
  BRAND: 'brand',
  CAMPAIGN: 'campaign',
  DESIGN: 'design',
  PASS: 'pass',
  Notification: 'notification',
}

const AppleModel = {
  COUPON: 'coupon',
  TICKET: 'ticket',
  STORE: 'store',
  GENERIC: 'generic',
  BOARDING: 'boarding',
}

const Device = {
  ANDROID: 'android',
  APPLE: 'apple',
}

const CodeFormats = {
  QRCODE: 'PKBarcodeFormatQR',
  BARCODE: 'PKBarcodeFormatPDF417',
}

const AppleDataDetectorTypes = {
  PKDataDetectorTypePhoneNumber: 'PKDataDetectorTypePhoneNumber',
  PKDataDetectorTypeLink: 'PKDataDetectorTypeLink',
  PKDataDetectorTypeAddress: 'PKDataDetectorTypeAddress',
  PKDataDetectorTypeCalendarEvent: 'PKDataDetectorTypeCalendarEvent',
}

const PassTypes = {
  COUPON: 'coupon',
  TICKET: 'ticket',
  MEMBERSHIP: 'membership',
  LOYALTY: 'loyalty',
  STAMP: 'stamp',
}

export {
  FieldReferencePlatform,
  FieldReferenceTable,
  AppleModel,
  AppleDataDetectorTypes,
  Device,
  CodeFormats,
  PassTypes,
}
