import React from 'react'

function ScanIcon({ size, className }) {
  return (
    <svg
      class={`text-inherit ${className}`}
      width={size || '24'}
      height={size || '24'}
      viewBox='0 0 24 24'
      stroke-width='2'
      stroke='currentColor'
      fill='none'
      stroke-linecap='round'
      stroke-linejoin='round'
    >
      {' '}
      <path stroke='none' d='M0 0h24v24H0z' />{' '}
      <path d='M4 7v-1a2 2 0 0 1 2 -2h2' /> <path d='M4 17v1a2 2 0 0 0 2 2h2' />{' '}
      <path d='M16 4h2a2 2 0 0 1 2 2v1' />{' '}
      <path d='M16 20h2a2 2 0 0 0 2 -2v-1' />{' '}
      <line x1='5' y1='12' x2='19' y2='12' />
    </svg>
  )
}

export default ScanIcon
