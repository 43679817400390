import { useAuth } from 'context/auth'
import ClickOutSide from 'hooks/click-outside'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

function ProfileButton() {
  const { currentUser, logout } = useAuth()
  const [openProfile, setOpenProfile] = useState(false)
  return (
    <div>
      <div>
        <button
          type="button"
          className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 "
          onClick={() => setOpenProfile(!openProfile)}
        >
          <span className="sr-only">Open user menu</span>
          <img
            className="w-8 h-8 object-cover bg-white rounded-full"
            src={currentUser.avatar || 'https://placehold.co/600x600'}
            alt="user"
          />
        </button>
      </div>
      <ClickOutSide onClickOutside={() => setOpenProfile(false)}>
        {openProfile && (
          <div className="z-50 my-4 absolute m-0 right-0 top-10 text-base list-none bg-white divide-y divide-gray-100 rounded shadow ">
            <div className="px-4 py-3" role="none">
              <p className="text-sm text-gray-900 " role="none">
                {currentUser.fullname}
              </p>
              <p
                className="text-sm font-medium text-gray-900 truncate "
                role="none"
              >
                {currentUser.email}
              </p>
            </div>
            <ul className="py-1" role="none">
              <li>
                <Link
                  to="./profile"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 "
                  role="menuitem"
                  onClick={() => setOpenProfile(false)}
                >
                  Profile
                </Link>
              </li>

              <li>
                <a
                  role="button"
                  onClick={logout}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 "
                >
                  Sign out
                </a>
              </li>
            </ul>
          </div>
        )}
      </ClickOutSide>
    </div>
  )
}

export default ProfileButton
