import { useEffect, useState } from 'react';

export default function useScript(url) {
    const [scriptLoaded, setScriptLoaded] = useState(false);

    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';

        const handleScriptLoad = () => {
            setScriptLoaded(true);
        };

        script.src = url;
        script.addEventListener('load', handleScriptLoad);

        document.head.appendChild(script);

        return () => {
            script.removeEventListener('load', handleScriptLoad);
            document.head.removeChild(script);
        };
    }, [url]);

    return scriptLoaded;
}
