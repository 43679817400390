const MODULAR_EVENTS = {
  CAMPAIGN: 'campaign',
  CUSTOMER: 'customer',
  LOCATION: 'location',
  STAFF: 'staff',
  REWARD: 'reward',
}

const CAMPAIGN_EVENTS = {
  OPTIN: 'optin',
  REDEEMED: 'redeemed',
  EXPIRED: 'expired',
  REMINDER: 'reminder',
}

const CUSTOMER_EVENTS = {
  CREATED: 'created',
  UPDATED: 'updated',
  DELETED: 'deleted',
  TOTAL_METRICS: 'total_metrics',
}

const LOCATION_EVENTS = {
  GENERAL_DETAILS_UPDATED: 'general_details_updated',
  TOTAL_METRICS: 'total_metrics',
}

const REWARD_EVENTS = {
  REDEEM_A_REWARD: 'redeem a reward',
  UNLOCK_REWARD: 'unlock reward',
}

const STAFF_EVENTS = {
  CREATED: 'created',
  UPDATED: 'updated',
  DELETED: 'deleted',
  REDEMPTION: 'redemption',
}

const EVENT_TYPES = {
  REGISTERED: 'registered',
  ADD_TO_WALLET: 'add_to_wallet',
  REDEEM: 'redeem',
  PASS_UPDATES: 'pass_updates',
  UNREGISTERED: 'unregistered',
}
export {
  EVENT_TYPES,
  MODULAR_EVENTS,
  CAMPAIGN_EVENTS,
  CUSTOMER_EVENTS,
  LOCATION_EVENTS,
  STAFF_EVENTS,
  REWARD_EVENTS,
}
