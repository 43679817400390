import React from 'react'

function LocationIcon({className}) {
  return (
    <svg
      class={`h-6 w-6 text-inherit ${className}`}
      fill='none'
      viewBox='0 0 24 24'
      stroke='currentColor'
    >
      <path
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        d='M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z'
      />
      <path
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        d='M15 11a3 3 0 11-6 0 3 3 0 016 0z'
      />
    </svg>
  )
}

export default LocationIcon
