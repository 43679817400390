import Loader from 'component/loader'
import React from 'react'
const { Suspense } = require('react')

const LoaderWrap = () => (
  <div className='tw-w-full tw-h-[100vh] tw-flex tw-flex-col tw-items-center tw-justify-center'>
    <Loader size={8} />
  </div>
)

const ComponentLoader = (Component) => (props) =>
  (
    <Suspense fallback={<LoaderWrap />}>
      <Component {...props} />
    </Suspense>
  )

export default ComponentLoader
